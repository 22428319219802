<template>
	<el-dialog
			v-model="props.servicePointShow"
			title=""
			:before-close="handleClose"
			lock-scroll
	>
		<div class="supportStart-9">
			<div class="supportStart-9-1">
				<div class="supportStart-9-1-1">请选择省/市/区(县)：</div>
				<div class="m-4">
					<el-select
							@change="provinceEvent"
							v-model="position.province"
							class="m-2"
							placeholder="请选择省"
							size="large"
					>
						<el-option
								v-for="(item, index) in listprovince"
								:key="index"
								:label="item"
								:value="item"
						/>
					</el-select>
				</div>
				<div class="m-4">
					<el-select
							@change="cityEvent"
							v-model="position.city"
							class="m-2"
							placeholder="请选择城市"
							size="large"
					>
						<el-option
								v-for="(item, index) in listcity"
								:key="index"
								:label="item"
								:value="item"
						/>
					</el-select>
				</div>
				<div class="m-4">
					<el-select
							v-model="position.area"
							class="m-2"
							placeholder="请选择城市"
							size="large"
					>
						<el-option
								v-for="(item, index) in listarea"
								:key="index"
								:label="item"
								:value="item"
						/>
					</el-select>
				</div>
				<div class="supportStart-9-1-2" @click="searchPosition">
					<img
							style="width: 14px; height: 14px"
							src="../../support/images/search.png"
					/>
					<span class="search">搜索</span>
				</div>
			</div>
			<div style="height: 500px;overflow-y: auto">
				<div class="supportStart-9-2" v-for="item in positionList.records">
					<div>
						<div class="supportStart-9-3">
							宁美授权服务中心{{ item.siteCode }}
						</div>
						<div class="supportStart-9-4">
							客服经理：<span>{{ item.manager }}</span>
						</div>
						<div class="supportStart-9-5">
							电&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp话：<span>{{
								item.businessMobile
							}}</span>
						</div>
						<div class="supportStart-9-6">
							地&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp址：<span>{{
								item.address
							}}</span>
						</div>
					</div>
					<el-divider
							border-style="#E1E1E1;"
							style="
		                  background-color: #e1e1e1;
		                "
					/>
				</div>
			</div>
			<div class="demo-pagination-block" v-if="positionList.length != 0">
				<el-pagination
						:current-page="positionList.current"
						:page-size="positionList.size"
						:small="small"
						background
						layout="prev, pager, next, jumper,total"
						:total="positionList.total"
						next-text="下一页"
						prev-text="上一页"
						@current-change="handleCurrentChange1"
				/>
				<el-button size="mini" style="margin-left: 10px">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script setup>

import {listArea, listCity, listProvince, pageListSite} from "@/api/support";
import {defineEmits, defineProps, onMounted, reactive, ref} from "vue";

onMounted(()=>{
	searchPosition();
	listProvince().then((res) => {
		listprovince.value = res.data;
	});
})

const props = defineProps(["servicePointShow"]);
const emit = defineEmits(["closeDialog"]);
// 关闭弹出框
const handleClose = () => {
	emit("closeDialog");
};

const listprovince = ref([]); // 省份数据
const listcity = ref([]); // 城市数据
const listarea = ref([]); // 区县数据

const position = reactive({
	pageNum: null,
	pageSize: null,
	province: null,
	city: null,
	area: null,
});
// 选择省
const provinceEvent = (val) => {
	listCity(val).then((res) => {
		listcity.value = res.data;
	});
};
//选择市
const cityEvent = (val) => {
	listArea(val).then((res) => {
		listarea.value = res.data;
	});
};

//搜索
const positionList = ref([]);
const searchPosition = () => {
	pageListSite(position).then((res) => {
		positionList.value = res.data;
	});
};

//分页
const handleCurrentChange1 = (pageNum) => {
	searchPosition((position.pageNum = pageNum));
};

</script>

<style scoped lang="less">
.supportStart-9 {
	height: 55%;
	background: #ffffff;
	position: relative;
	margin-bottom: 40px;
	.supportStart-9-1 {
		display: flex;
		padding: 0px 0 15px 0px;
		line-height: 40px;
		.supportStart-9-1-1 {
			width: 160px;
			height: 16px;
			font-size: 15px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}
		.supportStart-9-1-2 {
			margin-left: 24px;
			width: 90px;
			height: 40px;
			background: #c21917;
			border-radius: 5px;
			text-align: center;
			cursor: pointer;
			.search {
				width: 29px;
				height: 14px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;
				margin-left: 3px;
			}
		}
		.m-4 {
			width: 220px;
			height: 40px;
			border-radius: 5px;
		}
	}
	.supportStart-9-2 {
		.supportStart-9-3 {
			padding: 32px 0 23px 0;
			width: 765px;
			height: 20px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}
		.supportStart-9-4 {
			height: 17px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			padding: 22px 0 13px 0;
		}
		.supportStart-9-5 {
			height: 15px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			padding: 13px 0 13px 0;
		}
		.supportStart-9-6 {
			height: 17px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			padding: 13px 0 24px 0;
		}
	}
	.demo-pagination-block {
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}
	::v-deep .el-pagination.is-background .el-pager li.is-active {
		background-color: #c21917;
		color: #fff;
		border: #c21917;
	}
	::v-deep .el-pager li:hover {
		color: #c21917;
		border: 1px solid #c21917;
	}
	::v-deep .el-pagination button:hover {
		color: #c21917;
		border: 1px solid #c21917;
	}
	::v-deep .el-pagination.is-background .el-pager li {
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		background: transparent;
	}
	::v-deep .el-button:focus,
	.el-button:hover {
		color: #c21917;
		border-color: #c21917;
		background-color: transparent;
		outline: 0;
	}
	::v-deep .el-pagination.is-background .btn-next {
		background: transparent;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
	}
	::v-deep .el-pagination.is-background .btn-prev {
		background: transparent;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
	}
}
</style>