<template>
	<AppLayout>
		<div class="support-container">
			<img src="./images/banner.png" alt="" />
			<div class="supportPage">
				<img src="./images/title.png" alt="" class="title-img" />
				<div class="title">服务与支持</div>
				<div class="button-list">
					<div class="button-li" @mouseenter="handleEnter1" @mouseleave="handleLeave1">
						<img src="./images/快速保修.png" alt="" style="width: 31px; height: 31px" />
						<div style="margin-left: 19px">快速报修</div>
						<div v-if="backtwo">
							<img style="
                    position: absolute;
                    left: 30%;
                    top: 53%;
                    width: 523px;
                    height: 274px;
                    z-index: 100;
                  " src="../support/images/hand.png" alt="" />
						</div>
					</div>
					<div class="button-li" @click="clickAfterSaleQuery">
						<img src="./images/售后进度查询.png" alt="" style="width: 28px; height: 28px" />
						<div style="margin-left: 19px">售后进度查询</div>
					</div>
					<div class="button-li" @click="clickServicePoint">
						<img src="./images/网点查询.png" alt="" style="width: 31px; height: 31px" />
						<div style="margin-left: 19px">网点查询</div>
					</div>
					<div class="button-li" @click="clickAfterSalePolicy">
						<img src="./images/售后.png" alt="" style="width: 29px; height: 29px" />
						<div style="margin-left: 19px">售后服务政策</div>
					</div>
				</div>
				<div class="big-button-list">
					<div class="big-button-li" @click="handelClickPush">
						<img src="./images/在线申请售后.png" alt="" style="width: 29px; height: 29px" />
						<div class="big-button-li-text1">在线申请售后</div>
						<div class="big-button-li-text2">售后团队即时跟进处理您的问题</div>
						<div class="big-button-li-button">立即申请</div>
					</div>
					<div class="big-button-li" @click="handelService">
						<img src="./images/远程技术支持.png" alt="" style="width: 29px; height: 29px" />
						<div class="big-button-li-text1">远程技术支持</div>
						<div class="big-button-li-text2">技术顾问在线一对一技术指导</div>
						<div class="big-button-li-button">立即联系</div>
					</div>
					<div class="big-button-li" @click="router.push('/afterDelivery')">
						<img src="./images/交付售后.png" alt="" style="width: 29px; height: 29px" />
						<div class="big-button-li-text1">交付/售后</div>
						<div class="big-button-li-text2">
							标准交付与售后保障累计服务1000万+
						</div>
						<div class="big-button-li-button">立即查看</div>
					</div>
				</div>

				<!--增值服务-->
				<div style="margin-top: 52px" v-if="items.length">
					<div class="issue-title">增值服务</div>
					<div class="swiper-content">
						<swiper @swiper="setSwiper" :slides-per-view="4" :space-between="50">
							<swiper-slide v-for="item in items" :key="item.id">
								<div class="swiperItem">
									<div class="goodsItem" @click="goIncrementServie(item)">
										<img :src="item.picture" />
										<p class="title-1">{{ item.productName }}</p>
										<p class="title-2">
											{{ item.described }}
										</p>
									</div>
								</div>
							</swiper-slide>

						</swiper>
						<div  v-if="items.length>4">
              <div class="swiper-button-prev-custom" @click="prev" :class="{bgC:hasPre}">
                <img src="./images/left-black.png" alt="">
              </div>
              <div class="swiper-button-next-custom" @click="next" :class="{bgC:hasNext}">
                <img src="./images/right-black.png"  alt="">
              </div>
            </div>
					</div>
				</div>





				<!--热门问题-->
				<div style="margin-top: 52px">
					<div class="issue-title">热门问题</div>
					<div class="issue-container">
						<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
							<el-tab-pane v-for="item in issueType" :label="item.supportTypeName"
								:name="item.supportTypeName">
								<div v-for="item in issueList" class="issue-li"
									@click="router.push(`/supportSon/${item.id}`)">
									{{ item.title }}
								</div>
							</el-tab-pane>
						</el-tabs>
						<!--分页-->
						<div style="display: flex; justify-content: center; margin-top: 20px">
							<el-pagination :current-page="articleData.current" :page-size="articleData.size"
								:small="small" background layout="prev, pager, next, jumper,total"
								:total="articleData.total" next-text="下一页" prev-text="上一页"
								@current-change="handleCurrentChange" />
							<el-button size="mini" style="margin-left: 10px" v-if="articleData.records">确定</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<AfterSale :afterSaleShow="afterSaleShow" @closeDialog="closeDialog" />
		<ServicePoint :servicePointShow="servicePointShow" @closeDialog="closeDialog" />
	</AppLayout>
	<HomeBackup />
</template>

<script setup>
	import {
		Swiper,
		SwiperSlide
	} from "swiper/vue/swiper-vue";
	import "swiper/swiper-bundle.css";
	import AppLayout from "@/components/AppLayout";
	import HomeBackup from "@/views/home/components/HomeBackup";
	import {
		onMounted,
		ref
	} from "vue";
	import {
		ArticleDetails,
		TitleType
	} from "@/api/support";
	import AfterSale from "@/views/supportNew/components/AfterSale.vue";
	import ServicePoint from "@/views/supportNew/components/ServicePoint.vue";
	import emitter from "@/utils/eventBus";
	import {
		useRouter
	} from "vue-router";
	import {
		addedList
	} from "@/api/Home/index.js";


	const router = useRouter();


	//增值服务轮播图
	const mySwiper = ref(null)
	const items = ref([])
  const hasNext=ref(true)
  const hasPre=ref(false)

	const setSwiper = (e) => {
		mySwiper.value = e
	}
	const prev = () => {
		mySwiper.value.slidePrev()
    changeSwiper()
	}
	const next = () => {
		mySwiper.value.slideNext()
    changeSwiper()
	}

  function changeSwiper(){
    hasPre.value=mySwiper.value.realIndex!==0
    hasNext.value=items.value.length>mySwiper.value.realIndex+4
  }


	const getAddedList = async () => {
		try {
			let res = await addedList();
			if (res.code === 200) {
				items.value = [...res.data]
        changeSwiper()
			}
		} catch (error) {
			console.log(error);
		}
	};
	getAddedList()


	const goIncrementServie=(item)=>{
		const id=item.id
		router.push({
			path: "/incrementService",
			query:{
				id
			}
		})
	}






	let issueType = ref([]); // 热门问题分类
	onMounted(() => {
		TitleType().then((res) => {
			if (res.code == 200) {
				res.data.unshift({
					supportTypeName: "全部",
				});
				issueType.value = res.data;
				activeName.value = res.data[0].supportTypeName;
			}
		});
		getIssueList();
	});

	// 快速报修
	const backtwo = ref(false);
	const handleEnter1 = (res) => {
		backtwo.value = true;
	};
	const handleLeave1 = (res) => {
		backtwo.value = false;
	};

	let afterSaleShow = ref(false)
	// 点击售后进度查询
	const clickAfterSaleQuery = () => {
		afterSaleShow.value = true
	}
	const closeDialog = () => {
		afterSaleShow.value = false
		servicePointShow.value = false
	}

	let servicePointShow = ref(false)
	// 点击网点查询
	const clickServicePoint = () => {
		servicePointShow.value = true
	}
 const isFalse=ref(false)

	// 点击售后政策
	const clickAfterSalePolicy = () => {
		router.push({
			path: "/afterSalePolicy"
		})
	}

	// 点击在线申请售后
	const handelClickPush = () => {
		const token = localStorage.getItem("token");
		if (token) {
			router.push({
				path: "/orderCenter",
				query: {
					currentActive: 4,
				},
			});
		} else {
			emitter.emit("openLogin", {
				openLogin: true
			});
		}
	};

	// 点击远程技术支持
	const handelService = () => {
		emitter.emit("onlineClient");
	};

	let query = ref({
		pageNum: 1,
		pageSize: 10,
		typeName: null,
		title: null,
	});
	let issueList = ref([]); // 热门问题内容列表
	let articleData = ref([]); // 热门问题列表
	// 查询热门问题内容列表
	const getIssueList = () => {
		ArticleDetails(query.value).then((res) => {
			if (res.code == 200) {
				articleData.value = res.data;
				issueList.value = res.data.records;
			}
		});
	};

	const activeName = ref(""); // 默认问题分类
	// 热门问题分类改变时
	const handleClick = (tab, event) => {
		if (tab.paneName == "全部") {
			query.value.typeName = null;
		} else {
			query.value.typeName = tab.paneName;
		}
		query.value.pageNum = 1;
		getIssueList();
	};

	// 当前页码改变时
	const handleCurrentChange = (pageNum) => {
		query.value.pageNum = pageNum;
		getIssueList();
	};
</script>

<style lang="less" scoped>
	.swiper-content {
		position: relative;
		height: 380px;



		.swiperItem{
			padding-top: 40px;
		}

		.goodsItem {
			cursor: pointer;
			border-radius: 5px;
			height: 330px;
			background-color: #ffffff;
			.hoverShadow ();
			padding: 20px 20px 0 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			img {
				width: 80%;
				height: 200px;
			}

			.title-1 {
				width: 237px;
				font-size: 16px;
				font-weight: 400;
				color: #333;
				text-align: center;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
				margin: 10px auto;
				margin-bottom: 13px;
			}

			.title-2 {
				width: 237px;
				font-size: 13px;
				font-weight: 400;
				color: #666;
				text-align: center;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;
				margin: 0 auto;
			}

		}
    .swiper-button-next-custom,
		.swiper-button-prev-custom{
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      background: white;
      bottom:-30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d3d0d0;
    }

    .swiper-button-prev-custom{
      left: 45%;
      img{
        transform: translateX(-1px);
      }
    }
		.swiper-button-next-custom {
      right: 45%;
      img{
        transform: translateX(1px);
      }
		}
    .bgC{
      background: rgba(117,115,115,.4);
    }




	}

	.support-container {
		width: 1920px;
		margin: 0 auto;

		.supportPage {
			width: 1280px;
			margin: 0 auto;

			.title-img {
				margin: 0 auto;
				display: block;
				width: 540px;
				height: 42px;
			}

			.title {
				position: absolute;
				margin-top: -24px;
				left: 50%;
				transform: translateX(-50%);
				height: 35px;
				line-height: 35px;
				font-family: PingFang SC;
				font-weight: bold;
				font-size: 36px;
				color: #000000;
			}

			.button-list {
				display: flex;
				justify-content: space-between;
				margin-top: 94px;

				.button-li {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 297px;
					height: 96px;
					background: #ffffff;
					border-radius: 5px;
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 22px;
					color: #000000;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.big-button-list {
				display: flex;
				justify-content: space-between;
				margin-top: 30px;

				.big-button-li {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 408px;
					height: 242px;
					background: #ffffff;
					border-radius: 5px;
					cursor: pointer;

					.big-button-li-text1 {
						margin-top: 24px;
						height: 22px;
						line-height: 22px;
						font-family: PingFang SC;
						font-weight: 400;
						font-size: 22px;
						color: #000000;
					}

					.big-button-li-text2 {
						margin-top: 15px;
						height: 17px;
						line-height: 17px;
						font-family: PingFang SC;
						font-weight: 400;
						font-size: 18px;
						color: #808490;
					}

					.big-button-li-button {
						margin-top: 22px;
						width: 120px;
						height: 38px;
						line-height: 38px;
						background: #000000;
						border-radius: 10px;
						text-align: center;
						font-family: PingFang SC;
						font-weight: 400;
						font-size: 18px;
						color: #ffffff;
					}
				}
			}

			.issue-title {
				text-align: center;
				font-family: PingFang SC;
				font-weight: bold;
				font-size: 36px;
				color: #000000;
			}

			.issue-container {
				margin-top: 28px;
				width: 100%;
				padding: 26px 81px 85px 95px;
				background-color: #fff;

				.demo-tabs {
					.issue-li {
						width: 1000px;
						height: 71px;
						line-height: 71px;
						border-bottom: 2px solid #f8f8f8;
						font-family: PingFang SC;
						font-weight: 500;
						font-size: 20px;
						color: #808490;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					::v-deep .el-tabs__nav-wrap::after {
						background-color: #f8f8f8;
						height: 2px;
					}

					::v-deep .el-tabs__item {
						min-width: 125px;
						margin-right: 58px;
						padding: 0;
						padding-bottom: 13px !important;
						font-family: PingFang SC;
						font-weight: 500;
						font-size: 24px;
						color: #000000;
					}

					::v-deep .el-tabs__active-bar {
						min-width: 125px;
						height: 4px;
						background: #c21917;
					}
				}

				::v-deep .el-pagination.is-background .el-pager li.is-active {
					background-color: #c21917;
					color: #fff;
					border: #c21917;
				}

				::v-deep .el-pager li:hover {
					color: #c21917;
					border: 1px solid #c21917;
				}

				::v-deep .el-pagination button:hover {
					color: #c21917;
					border: 1px solid #c21917;
				}

				::v-deep .el-pagination.is-background .el-pager li {
					border: 1px solid #d9d9d9;
					border-radius: 5px;
					background: transparent;
				}

				::v-deep .el-button:focus,
				.el-button:hover {
					color: #c21917;
					border-color: #c21917;
					background-color: transparent;
					outline: 0;
				}

				::v-deep .el-pagination.is-background .btn-next {
					background: transparent;
					border: 1px solid #d9d9d9;
					border-radius: 5px;
				}

				::v-deep .el-pagination.is-background .btn-prev {
					background: transparent;
					border: 1px solid #d9d9d9;
					border-radius: 5px;
				}
			}
		}
	}
</style>
